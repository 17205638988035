import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pond,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  InfoControl,
  MultiInfoButton,
  SequenceControl,
  ToggleControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import selectorToggle from 'isno/lib/static/images/selectorToggle.svg';
import selectorToggleRotateRed from 'isno/lib/static/images/selectorToggleRotateRed.svg';
import selectorToggleRotate from 'isno/lib/static/images/selectorToggleRotate.svg';
import strainerGreen from 'isno/lib/static/images/strainerGreen.svg';
import strainerRed from 'isno/lib/static/images/strainerRed.svg';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
// import { LanguageContext } from 'isno/lib/components/language';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  sealTemp: 'seal_temperature',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  wellLevel: 'transfer_system_well_level',
  ib1: 'ib1_open_close',
  ib2: 'ib2_open_close',
  ib3: 'ib3_open_close',
  ib4: 'ib4_open_close',
  toPondFlow: 'water_flow_to_connector_pond',
  fromPondFlow: 'water_flow_from_pond',
  riverLevel: 'river_flow',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function BarkerScreen(props) {
  const theme = useTheme();
  // const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });
  const [strainSource, setStrainSource] = useState(strainerGreen);

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLC(3),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(2);
    props.fetchPLC(3);
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationequipment2 = props.locations?.locations?.[2]?.equipment;

  useEffect(() => {
    const supply = locationequipment2?.Other?.ioValues?.supply_pressure?.value ? locationequipment2?.Other?.ioValues?.supply_pressure?.value : 0;
    const suction = locationequipment2?.Other?.ioValues?.suction_water_pressure?.value ? locationequipment2?.Other?.ioValues?.suction_water_pressure?.value : 0;
    setStrainSource(supply - 15 > suction ? strainerRed : strainerGreen);
  }, [
    locationequipment2?.Other?.ioValues?.supply_pressure?.value,
    locationequipment2?.Other?.ioValues?.suction_water_pressure?.value,
  ]);
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  const componentMinWidth = '70px';
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer flex="3" border backgroundColor={theme.backgroundColor} label="Barker Pumphouse">
            <InfoControlRow>
              <VFDPumpControl
                title="P9 PID Control"
                pumpData={{ id: `controlValve_${locationequipment2?.['P9 PID']?.id}` }}
                pidData={locationequipment2?.['P9 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                settings
              />
              <VFDPumpControl
                title="P10 PID Control"
                pumpData={{ id: `controlValve_${locationequipment2?.['P10 PID']?.id}` }}
                pidData={locationequipment2?.['P10 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                settings
              />
              <Info
                img={locationequipment2?.Other?.ioValues?.panel_auto_switch?.value ? selectorToggleRotate : locationequipment2?.Other?.ioValues?.panel_hand_switch?.value ? selectorToggleRotateRed : selectorToggle}
                title="Panel Switch"
                statistic={locationequipment2?.Other?.ioValues?.panel_auto_switch?.value ? 'Auto' : locationequipment2?.Other?.ioValues?.panel_hand_switch?.value ? 'Hand' : 'Off'}
                label=""
                color={locationequipment2?.Other?.ioValues?.panel_auto_switch?.value ? theme.onGreen : locationequipment2?.Other?.ioValues?.panel_hand_switch?.value ? theme.alarm : ''}
                imgStyle="margin-top: 8px; max-height: 35px; margin-right: -7px;"
              />
              <MultiInfo
                title="Pump Panels"
                subtitles={['P1:', 'P2:', 'P3:', 'P4:', 'P5:', 'P6:', 'P7:', 'P8:', 'P9:', 'P10:', 'P11:']}
                statistics={[
                  locationequipment2?.P1?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P2?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P3?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P4?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P5?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P6?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P7?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P8?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P9?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P10?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment2?.P11?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                ]}
                colors={[
                  locationequipment2?.P1?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P2?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P3?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P4?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P5?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P6?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P7?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P8?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P9?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P10?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment2?.P11?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                ]}
                ids={[
                  locationequipment2?.P1?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P2?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P3?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P4?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P5?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P6?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P7?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P8?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P9?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P10?.ioValues?.panel_on_off?.id,
                  locationequipment2?.P11?.ioValues?.panel_on_off?.id,
                ]}
                labels={[]}
                styles="flex-direction: row; flex-wrap: wrap; justify-content: flex-start; width: 334px;"
              />
              <MultiInfoButton
                title="System Gallons"
                titles={['Trip', 'Total']}
                statistics={[
                  locationequipment2?.FT?.ioValues?.trip_gallons?.value,
                  locationequipment2?.FT?.ioValues?.total_gallons?.value,
                ]}
                labels={[
                  locationequipment2?.FT?.ioValues?.trip_gallons?.ioValueType?.units,
                  locationequipment2?.FT?.ioValues?.total_gallons?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationequipment2?.FT?.ioValues?.trip_gallons_reset?.id,
                  locationequipment2?.FT?.ioValues?.total_gallons_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <ToggleControl
                title="Ventilator"
                ioData={locationequipment2?.Other}
                IOValueKeys={{
                  start: 'ventilation_start',
                  stop: 'ventilation_stop',
                  started: 'ventilation_start_stop_output',
                  stopped: 'ventilation_start_stop_output',
                }}
                setIOValue={props.setIOValue}
                button1Text="Enable"
                button2Text="Disable"
                buttonWidth="55px"
                writeValues={[1, 1]}
                readValues={[1, 0]}
                dot
              // ioData: EquipmentPropType.isRequired,
              // IOValueKeys: PropTypes.shape({
              //   start: PropTypes.string,
              //   stop: PropTypes.string,
              //   started: PropTypes.string,
              //   stopped: PropTypes.string,
              // }).isRequired,
              // setIOValue: PropTypes.func.isRequired,
              // title: PropTypes.string,
              // button1Text: PropTypes.string,
              // button2Text: PropTypes.string,
              // buttonWidth: PropTypes.string,
              // writeValues: PropTypes.arrayOf(PropTypes.number),
              // readValues: PropTypes.arrayOf(PropTypes.number),
              // dot: PropTypes.bool,
              />
              <SequenceControl
                title="Sequencer"
                pumpsHeight="469px"
                pumpsWidth="577px"
                setIOValue={props.setIOValue}
                leadVFD={locationequipment2?.Sequencer?.ioValues?.sequence_lead_vfd}
                leadVFDId={locationequipment2?.Sequencer?.ioValues?.sequence_lead_vfd?.value ? locationequipment2?.P10?.id : locationequipment2?.P9?.id}
                leadVFDlabel1={locationequipment2?.P9?.name}
                leadVFDlabel2={locationequipment2?.P10?.name}
                sequencerData={locationequipment2?.Sequencer}
                id={locationequipment2?.Sequencer?.id}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                pumps={[
                  locationequipment2?.P1,
                  locationequipment2?.P2,
                  locationequipment2?.P3,
                  locationequipment2?.P4,
                  locationequipment2?.P5,
                  locationequipment2?.P6,
                  locationequipment2?.P7,
                  locationequipment2?.P8,
                  locationequipment2?.P9,
                  locationequipment2?.P10,
                  locationequipment2?.P11,
                ]}
                disableEnable={[
                  locationequipment2?.Sequencer?.ioValues?.p1_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p2_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p3_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p4_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p5_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p6_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p7_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p8_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p9_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p10_disable_enable,
                  locationequipment2?.Sequencer?.ioValues?.p11_disable_enable,
                ]}
                setpoints={[
                  locationequipment2?.Sequencer?.ioValues?.p1_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p2_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p3_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p4_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p5_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p6_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p7_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p8_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p9_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p10_sequence_order_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.p11_sequence_order_setpoint,
                ]}
                startParam={[
                  locationequipment2?.Sequencer?.ioValues?.start_fast_offset_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.start_fast_timer_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.start_slow_offset_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.start_slow_timer_setpoint,
                ]}
                startValues={[
                  locationequipment2?.Sequencer?.ioValues?.start_fast_offset,
                  locationequipment2?.Sequencer?.ioValues?.start_fast_timer,
                  locationequipment2?.Sequencer?.ioValues?.start_slow_offset,
                  locationequipment2?.Sequencer?.ioValues?.start_slow_timer,
                ]}
                startLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Timer',
                ]}
                stopParam={[
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_offset_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_seal_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_speed_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_timer_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_offset_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_seal_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_speed_setpoint,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_timer_setpoint,
                ]}
                stopValues={[
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_offset,
                  null,
                  null,
                  locationequipment2?.Sequencer?.ioValues?.stop_fast_timer,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_offset,
                  null,
                  null,
                  locationequipment2?.Sequencer?.ioValues?.stop_slow_timer,
                ]}
                stopLabels={[
                  'Fast Offset',
                  'Fast Seal',
                  'Fast Speed',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Seal',
                  'Slow Speed',
                  'Slow Timer',
                ]}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[3])} // plcId 3: Barker Pumphouse
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[3], theme)}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationequipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'master_alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowCustom height="100px">
              <PipePlaceholder hidden minWidth="30px" />
              <PipePlaceholder hidden minWidth="30px" />
              <PipePlaceholder size="large" pipeAbove="right" connectBelow />
              <div css={styles.flexRow}>
                <PipePlaceholder size="large" minWidth="0px" horizontalAbove color={theme.pipeColors.water} />
                <Statistic
                  size="large"
                  label={locationequipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  border
                  statistic={locationequipment2?.Other?.ioValues?.discharge_water_pressure?.value}
                />
                {/* <Statistic
                    precision={1}
                    label={locationequipment2?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value}
                  /> */}
                <PipePlaceholder minWidth="0px" size="large" capAfter horizontalAbove color={theme.pipeColors.water} />
                <div css={[css`position: absolute; top: 30px; left: 203px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {left: -2px}`]}>
                  Valley
                </div>
              </div>
              <div css={css`min-width: 35px; max-width: 35px;`} />
              {/* <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeAbove="large"
                pipeAbove="right"
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.BP1 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[locationequipment2?.BP1?.ioValues?.low_amps]}
                stats={[
                  {
                    stat: locationequipment2?.BP1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.BP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.BP1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.BP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeAbove="large"
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.BP2 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[locationequipment2?.BP2?.ioValues?.low_amps]}
                stats={[
                  {
                    stat: locationequipment2?.BP2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.BP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.BP2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.BP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              /> */}
              {/* <div css={styles.flexRow}>
                <PipePlaceholder minWidth="0px" size="large" horizontalAbove color={theme.pipeColors.water} />
                <Statistic
                  size="large"
                  label={locationequipment2?.Other?.ioValues?.boosted_discharge_water_pressure?.ioValueType?.units}
                  border
                  statistic={locationequipment2?.Other?.ioValues?.boosted_discharge_water_pressure?.value}
                />
                <PipePlaceholder minWidth="0px" size="large" capAfter horizontalAbove color={theme.pipeColors.water} />
                <div css={[css`position: absolute; top: 28px; left: 0px;`]}>
                  To Jordan and Aurora
                </div>
              </div> */}
            </PumpsRowCustom>
            <PumpsRowMedium>
              <PipePlaceholder hidden minWidth={componentMinWidth} />
              <PipePlaceholder hidden minWidth={componentMinWidth} />
              <PipePlaceholder connectBelow pipeAbove="right" size="large" minWidth={componentMinWidth} />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                componentMinWidth={componentMinWidth}
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P2 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P2?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder pipeAbove="middle" size="large" connectBelow minWidth={componentMinWidth} />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                componentMinWidth={componentMinWidth}
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P4 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P4?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder pipeAbove="middle" size="large" connectBelow minWidth={componentMinWidth} />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                componentMinWidth={componentMinWidth}
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P6 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P6?.ioValues?.low_amps,
                  locationequipment2?.P6?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder pipeAbove="middle" size="large" connectBelow minWidth={componentMinWidth} />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                componentMinWidth={componentMinWidth}
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P8 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P8?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P8?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P8?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P8?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P8?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder pipeAbove="middle" size="large" connectBelow minWidth={componentMinWidth} />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                componentMinWidth={componentMinWidth}
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P10 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  speed: 'speed',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P10?.ioValues?.fault,
                  locationequipment2?.P10?.ioValues?.emergency_stop,
                  locationequipment2?.P10?.ioValues?.low_amps,
                  locationequipment2?.P10?.ioValues?.high_seal_temperature,
                ]}
                statuses={[
                  {
                    id: locationequipment2?.P10?.ioValues?.local_remote?.id,
                    status: locationequipment2?.P10?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P10?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P10?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P10?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P10?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P10?.ioValues?.[IOValueKeys.sealTemp]?.value,
                    label: locationequipment2?.P10?.ioValues?.[IOValueKeys.sealTemp]?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <PipePlaceholder pipeAbove="left" size="large" connectBelow minWidth="160px" />
              <PipePlaceholder hidden minWidth={componentMinWidth} />
              {/* <PipePlaceholder hidden minWidth="40px" /> */}
            </PumpsRowMedium>
            <PumpsRowMedium>
              <div css={[styles.flexRow, css`min-width: ${componentMinWidth}`]}>
                <PipePlaceholder size="large" horizontalAbove capBefore minWidth={`${componentMinWidth}`} />
                <div css={[css`position: absolute; top: -40px; left: 25px; width: 70px;`]}>
                  From River 1+2
                </div>
              </div>
              <div css={[styles.flexCol, css`min-width: ${componentMinWidth}`]}>
                <PipePlaceholder size="large" pipeAbove="left" minWidth={`${componentMinWidth}`} />
                <div css={css`width: 52px; display: flex; justify-content: center;`}>
                  <Statistic
                    // size="large"
                    label={locationequipment2?.Other?.ioValues?.supply_pressure?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues?.supply_pressure?.value}
                  />
                </div>
                <div css={css`height: 35px; position: relative;`}>
                  <PipePlaceholder size="large" minWidth={`${componentMinWidth}`} />
                  <img alt="strainer" src={strainSource} css={styles.strainer} />
                </div>
                <div css={css`width: 52px; display: flex; justify-content: center;`}>
                  <Statistic
                    // size="large"
                    label={locationequipment2?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues?.suction_water_pressure?.value}
                    alertText={locationequipment2?.Other?.ioValues?.low_suction_pressure?.value ? 'Low Suction Pressure' : ''}
                    alertTop="-21px"
                    alertLeft="-76px"
                  />
                </div>
                <div css={css`width: 52px; display: flex; justify-content: center;`}>
                  <Statistic
                    // size="large"
                    label={locationequipment2?.Other?.ioValues?.suction_water_temperature?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues?.suction_water_temperature?.value}
                    precision={1}
                  />
                </div>
                <div css={css`width: 52px; display: flex; justify-content: center;`}>
                  <Statistic
                    // size="large"
                    label={locationequipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues?.discharge_water_flow?.value}
                  />
                </div>
                <PipePlaceholder size="large" pipeSizeBelow="large" pipeBelow="right" minWidth={`${componentMinWidth}`} />
              </div>
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="middle"
                componentMinWidth={componentMinWidth}
                percent
                setIOValue={props.setIOValue}
                pumpData={locationequipment2?.P1}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P1?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeSizeBelow="large" pipeBelow="middle" connectAbove size="large" />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="middle"
                componentMinWidth={componentMinWidth}
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P3 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P3?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeSizeBelow="large" pipeBelow="middle" connectAbove size="large" />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="middle"
                componentMinWidth={componentMinWidth}
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P5 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P5?.ioValues?.low_amps,
                  locationequipment2?.P5?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeSizeBelow="large" pipeBelow="middle" connectAbove size="large" />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="middle"
                componentMinWidth={componentMinWidth}
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P7 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P7?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P7?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P7?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeSizeBelow="large" pipeBelow="middle" connectAbove size="large" />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="middle"
                componentMinWidth={componentMinWidth}
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P9 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  speed: 'speed',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P9?.ioValues?.fault,
                  locationequipment2?.P9?.ioValues?.emergency_stop,
                  locationequipment2?.P9?.ioValues?.low_amps,
                  locationequipment2?.P9?.ioValues?.high_seal_temperature,
                ]}
                statuses={[
                  {
                    id: locationequipment2?.P9?.ioValues?.local_remote?.id,
                    status: locationequipment2?.P9?.ioValues?.local_remote?.value,
                    statusKey: {
                      0: 'Local',
                      1: 'Remote',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P9?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P9?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P9?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P9?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P9?.ioValues?.[IOValueKeys.sealTemp]?.value,
                    label: locationequipment2?.P9?.ioValues?.[IOValueKeys.sealTemp]?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeSizeBelow="large" pipeBelow="middle" connectAbove size="large" />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-15px"
                pipeSizeBelow="large"
                pipeBelow="left"
                componentMinWidth="160px"
                percent
                setIOValue={props.setIOValue}
                pumpData={{ ...locationequipment2?.P11 }}
                otherData={locationequipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                alarms={[
                  locationequipment2?.P11?.ioValues?.fault,
                  locationequipment2?.P11?.ioValues?.low_amps,
                ]}
                stats={[
                  {
                    stat: locationequipment2?.P11?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment2?.P11?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment2?.P11?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment2?.P11?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={[styles.flexRow, css`min-width: ${componentMinWidth}`]}>
                {/* <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-15px"
                  pipeSizeBelow="large"
                  pipeBelow="left"
                  componentMinWidth={componentMinWidth}
                  pipeAbove="right"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationequipment2?.GP1 }}
                  otherData={locationequipment2?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 1]}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[locationequipment2?.GP1?.ioValues?.low_amps]}
                  stats={[
                    {
                      stat: locationequipment2?.GP1?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment2?.GP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment2?.GP1?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment2?.GP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                /> */}
              </div>
              {/* <PipePlaceholder minWidth="40px" horizontalAbove capAfter capLabel="To Golf Course" capLabelTop="-25px" capLabelLeft="-110px" /> */}
              {/* <PipePlaceholder minWidth="40px" hidden /> */}
            </PumpsRowMedium>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      min-width: 175px;
      position: relative;
      height: 100%;
    `,
    strainer: css`
      width: 35px;
      height: 35px;
      position: absolute;
      top: 0px;
      left: 8px;
    `,
    flexCol: css`
    flex-direction: column;
      display: flex;
      flex: 1;
      position: relative;
      height: 100%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

BarkerScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

BarkerScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(BarkerScreen);
